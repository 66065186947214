.home {
    overflow: hidden;

    &__hero {
        position: relative;
        min-height: 700px;
        // overflow: hidden;
        margin-top: -25%;

        @keyframes scrollDown {
            0% {
                transform: translateY(0);
                opacity: 0;
            }

            80% {
                transform: translateY(80%);
                opacity: 1;
            }

            100% {
                transform: translateY(100%);
                opacity: 1;
            }
        }

        ._arrow-down {
            display: none;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50vh;
            right: 5%;
            animation: scrollDown .8s linear infinite forwards;
            z-index: 10;

            @include mq(sm) {
                display: block;
            }

            img {
                transform: rotate(90deg);
                width: 40px;
                height: 40px;

                @include mq(sm) {
                    width: 60px;
                    height: 60px;
                }
            }

            @include mq(sm) {
                width: 60px;
                height: 60px;
            }
        }

        @include mq(md) {
            min-height: 805px;
            margin-top: 0;
        }

        &__backdrop-mobile {
            height: 100%;
            z-index: -1;

            @include mq(sm) {
                display: none;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__backdrop-tablet {
            display: none;
            height: 100%;
            z-index: -1;

            @include mq(sm) {
                display: block;
            }

            @include mq(md) {
                display: none;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__backdrop-desktop {
            display: none;

            @include mq(md) {
                z-index: -1;
                display: block;
                height: 100%;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__characters {
            width: 55%;
            position: absolute;
            top: 51%;
            left: 0;
            right: 5%;
            margin: auto;

            @include mq(md) {
                width: 30%;
                top: 41%;
                bottom: auto;
            }

            img {
                width: 100%;
                height: auto;
            }

            &__container {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
            }
        }

        &__container {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            top: 7%;

            @include mq(md) {
                top: 15%;
            }
        }

        .col-xs-24,
        .row,
        .container {
            height: 100%;
        }

        &__wrapper {
            top: 10%;
        }

        &__title {
            display: none;

            @include mq(sm) {
                display: block;
                font-size: 58px;
                text-align: center;
                margin-top: 60px;
                text-transform: uppercase;
                font-weight: 700;
                color: $white;
            }

            span {
                font-size: 68px;

                ._yellow {
                    font-size: 1.2em;
                }
            }
        }

        &__subtitle {
            display: none;

            @include mq(sm) {
                display: block;
                text-align: center;
                font-size: 36px;
                margin-left: auto;
                margin-right: auto;
                width: 70%;
                font-weight: 100;
                color: $white;
            }
            
            span {
                font-weight: 700;
            }
        }

        &__slider {

            position: absolute;
            width: 100%;
            left: 0;
            top: 17%;

            @include mq(sm) {
                top: 25%;
            }

            @include mq(md) {
                top: 35%;
            }
            // button {
            //
            // }
            .slick-prev,
            .slick-next {
                // width: 30px !important;
                // height: 30px !important;
                display: block !important;
                top: 60%;

                &:before {
                    // content: '';
                    // height: 30px;
                    // width: 30px !important;
                    // z-index: 10;
                    display: none !important;
                }

                @include mq(md) {
                    display: none !important;
                }
            }

            .slick-next {
                background: url("/images/arrow-next.png") center center / cover !important;
                right: 10px;
            }

            .slick-prev {
                background: url("/images/arrow-prev.png") center center / cover !important;
                left: 10px;
            }
        }

        &__slide {
            @include mq(md) {
                width: 50%;
            }
        }

        &__group {
            text-align: center;
            width: 75%;
            margin-left: auto;
            margin-right: auto;

            @include mq(sm) {
                width: 50%;
            }

            &--left {
                @include mq(sm) {
                    margin-right: 29%;
                }
            }

            &--right {
                @include mq(sm) {
                    margin-left: 20%;
                    margin-right: 0;
                }
            }
        }

        &__slide-title {
            font-size: 32px;
            text-transform: uppercase;
            color: $yellow;
            font-weight: 600;

            @include mq(md) {
                font-size: 32px;
            }
        }

        &__slide-text {
            font-size: 18px;
            color: $white;

            @include mq(md) {
                color: $darkGray;
                font-size: 18px;
            } 

            &--red {
                color: $red;
            }
            
            span {
                font-weight: bold;
            }
        }
    }

    &__creacion {
        position: relative;
        z-index: 2;

        &__backdrop {
            height: 110vh;
            margin-top: -58%;
          //  margin-left: -45%;
            max-height: 700px;

            @include mq(sm) {
                margin-top: -18%;
                margin-left: 0;
                height: 60vh;
                max-height: none;
            }

            @include mq(md) {
                height: auto;
            }

            img {
                height: 100%;
                width: auto;

                @include mq(md) {
                    height: auto;
                    width: 100%;
                }
            }
        }

        .container {
            position: absolute;
            top: 10%;
            height: 100%;
            left: 0;
            right: 0;

            @include mq(sm) {
                left: 0;
                top: 25%;
                height: 45vh;
            }

            @include mq(md) {
                height: 100%;
            }
        }

        .row,
        .col-xs-24 {
            height: 100%;
        }

        ._slider {
            .slick-prev,
            .slick-next {
                display: none !important;           }
        }

        &__slider {
            width: 100%;
            height: 100%;
            margin-bottom: 0 !important;
            margin-top: 5%;

            @include mq(sm) {
                margin-top: 0;
            }

            .slick-dots {
                bottom: 22vh;

                @include mq(sm) {
                    bottom: 10vh;
                }

                @include mq(md) {
                    transform: translate(0);
                    right: 8%;
                    left: auto;
                    bottom: auto;
                    top: 40%;
                }

                li {
                    margin: 0 15px;

                    button {
                        &:before {
                            font-size: 15px;
                        }
                    }
                }
            }
        }

        .slick-list,
        .slick-track {
            height: 100%;
        }

        &__slide {
            @include mq(md) {
                //height: 45vh;
            }
        }

        &__slide-group {
            height: 100%;

            @include mq(sm) {
                display: flex;
            }
        }

        &__slide-image {
            height: 0;
            margin-bottom: 60px;
            padding-bottom: 56.5%;
            position: relative;

            @include mq(sm) {
                margin-bottom: 0;
                width: 60%;
                padding-right: 20px;
                padding-bottom: 33.9%;
            }

            img {
                width: 100%;
                height: auto;
            }

            iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 1%;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: 2;
                transform: rotate(1deg);
                box-shadow: 0 0 20px rgba(0,0,0,.5);
            }

            &:before {
                position: absolute;
                top:-20px;
                left:-20px;
                right:-20px;
                bottom:-20px;
                content: '';
                background: url('/images/middle-cover.png') no-repeat center center / cover;
            }
        }

        &__slide-content {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include mq(sm) {
                width: 30%;
                margin-left: auto;
            }
        }

        &__slide-title {
            font-size: 60px;
            margin-top: 0;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        &__slide-text {
            font-size: 24px;
            font-weight: 100;
            margin: 0;

            @include mq(md) {

            }
            
            span {
                font-weight: bold;

            }
        }

        // &__wrapper {
        //     width: 100%;
        //     height: 100%;
        // }
    }

    &__concurso {
        position: relative;
        background: 
            url('/images/back-bottom-top.jpg') no-repeat center top / 100%,        
            url('/images/back-bottom-bottom.jpg') no-repeat center bottom / 100%, 
            url('/images/back-bottom-middle.jpg') repeat-y center center / 100%;
        margin-top: -25%;
        padding-top: 25%;
        padding-bottom: 30vh;

        @include mq(sm) {
            padding-bottom: 60vh;
        }

        // &__decoration-top {
        //     position: absolute;
        //     top: -30px;
        //     right: 0;
        //     width: 30%;

        //     @include mq(md) {
        //         width: 10%;
        //         right: 17%;
        //         top: -65px;
        //     }

        //     img {
        //         width: 100%;
        //         height: auto;
        //     }
        // }

        &__heading {
            text-align: center;
        }

        &__legal,
        &__cargar-mas {
            border-radius: 999px;
            height: 50px;
            color: $white;
            background: $yellow;
            box-shadow: 0 6px 0 0 $yellow - #222;
            display: inline-block;
            font-size: 28px;
            font-family: $altFont;
            line-height: 50px;
            margin-bottom: 50px;
            font-family: $funny;
            text-shadow: 0 2px 0 $yellow - #222;
            letter-spacing: 2px;
            padding: 0 30px;
        }

        &__cargar-mas {
            margin-left: auto;
            margin-right: auto;
            display: block;
            max-width: 200px;
            text-align: center;
            margin-top: 60px;
            z-index: 1000;
            position: relative;

            &.-disabled {
                opacity: .5;
                pointer-events: none;
            }
        }

        &__characters {
            left: 0;
            right: 0;
            position: absolute;
            bottom: 0;
            z-index: 10;

            @include mq(md) {
                right: 0;
                width: 50%;
                left: auto;
            }

            img {
                width: 100%;
            }
        }

        // &__decoration-bottom {
        //     position: absolute;
        //     top: 250px;
        //     left: -65px;
        //     width: 30%;
        //     z-index: 5;

        //     @include mq(md) {
        //         width: 10%;
        //         left: 5%;
        //         top: 700px;
        //     }

        //     img {
        //         width: 100%;
        //         height: auto;
        //     }
        // }

        .container {
            padding-top: 0;
            padding-bottom: 100px;

            @include mq(md) {
                padding: 0;
            }

            &--modifier {
                padding: 0;
            }
        }

        &__title {
            color: $yellow;
            text-align: center;
            font-family: 'VTC-GarageSale';
            font-size: 60px;

            @include mq(md) {
                font-size: 160px;
            }
        }

        &__text {
            text-align: center;
            font-size: 20px;
            margin-bottom: 40px;
            width: 70%;
            margin-left: auto;
            margin-right: auto;
            
            @include mq(sm) {
                width: 100%;
                font-size: 30px;
            }
            
            span {
                font-weight: 600;
            }

            .-yellow {
                color:  $yellow;
            }
        }

        &__images-wrapper {
            width: 100%;
            position: relative;
            // background: 
 
        }

        &__images-group {
            .col-sm-12 {
                position: relative;

                &:nth-child(4n+1) {
                    &:after {
                        @include mq(sm) {
                            content: url("/images/foto-detail-left.png");
                            position: absolute;
                            top: 50%;
                            transform: translate3d(-80%, -50%, 0);
                        }
                    }
                }

                &:nth-child(4n+4) {
                    &:before {
                        @include mq(sm) {
                            content: url("/images/foto-detail-right.png");
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translate3d(50%, -50%, 0);
                            z-index: 2;
                        }
                    }
                }

                &:last-child {
                    &:after,
                    &:before {
                        display: none;
                    }
                }

                &:nth-last-child(2) {
                    &:after {
                        @include mq(sm) {
                            content: url("/images/foto-detail-bottom.png");
                            position: absolute;
                            left: 50%;
                            transform: translate3d(-50%, -50%, 0);
                            z-index: 2;
                            top: 100%;
                        }
                    }
                    
                    &:before {
                        display: none;
                    }
                }
            }
        }

        &__image {
            width: 100%;
            padding-bottom: 100%;
            // overflow: hidden;
            position: relative;
            height: 0;
            margin-bottom: 30px;
            border: 3px solid $yellow;
            border-radius: 5px;

            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
    }
}

.-red {
    color: $red;
}

._yellow {
    color: $yellow;
}

._big {
    font-size: 1.2em;
}