.footer {
    background: $yellow;

    &__container {
        // height: 10vh;
        padding: 40px 0;
        
        @include mq(md) {
            display: flex;
            justify-content: space-between;
            padding: 20px 0;
        }
    }

    &__logo {
        padding-bottom: 40px;
        height: 160px;

        @include mq(md) {
            margin-left: 0;
            margin-right: 0;
            width: auto;
            height: 80px;
            padding-bottom: 0;
        }

        img {
            display: block;
            height: 100%;
            width: auto;
            margin-left: auto;
            margin-right: auto;
            }
    }

    &__social {
        display: flex;
        justify-content: center;
        align-items: center;

        &__title {
            margin: 0;
            color: $black + #333;
            font-size: 30px;
            text-transform: uppercase;
        }

        &__list {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            li {
                margin-left: 20px;

                a{
                    i{
                        color: $black + #333;
                        font-weight: 900;
                        font-size: 24px;
                    }
                }
            }
        }
    }
}
