.header {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    padding: 20px 0;

    @include mq(sm) {
        padding: 40px 0;
    }

    &__logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 60%;

        @include mq(sm) {
            width: 20%;
        }

        @include mq(md) {
            width: 25%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
    // $h: &;

    // &__hamburguer {
    //     cursor: pointer;
    //     width: 25px;
    //     height: 25px;
    //     margin: auto;
    //     position: absolute;
    //     right: 32px;
    //     top: 30px;
    //
    //     span {
    //         background-color: $mainColor;
    //         border-radius: 2px;
    //         content: '';
    //         display: block;
    //         width: 100%;
    //         height: 2px;
    //
    //         &:nth-child(1) {
    //             animation:outT 0.6s $easing backwards;
    //             animation-direction:reverse;
    //         }
    //         &:nth-child(2) {
    //             margin: 7px 0;
    //             animation:outM 0.6s $easing backwards;
    //             animation-direction:reverse;
    //         }
    //         &:nth-child(3) {
    //             animation:outBtm 0.6s $easing backwards;
    //             animation-direction:reverse;
    //         }
    //     }
    // }
    //
    // &.-open {
    //     #{$h}__hamburguer {
    //         span:nth-child(1) {
    //             animation:inT 0.6s $easing forwards;
    //         }
    //         span:nth-child(2) {
    //             animation:inM 0.6s $easing forwards;
    //         }
    //         span:nth-child(3) {
    //             animation:inBtm 0.6s $easing forwards;
    //         }
    //     }
    //
    //     #{$h}__nav {
    //
    //     }
    // }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
